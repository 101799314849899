// TODO: create useDynamicClasses composable and move all class based composables to that file

export const useCurrentLanguageClass = (blockName: string) => {
  const { locale } = useI18n()
  const currentLanguageClass = computed(
    () => locale.value && `${blockName}--${locale.value}`,
  )

  return {
    currentLanguageClass,
  }
}
